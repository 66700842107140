import { WORDS } from './wordlist'

export const VALID_GUESSES = [
  'гааген',
  'гаагцы',
  'габала',
  'габара',
  'габаша',
  'габбро',
  'габеля',
  'габион',
  'габлиц',
  'гаваец',
  'гавайи',
  'гавана',
  'гавань',
  'гавиал',
  'гаврик',
  'гаврош',
  'гаврцы',
  'гагаку',
  'гагара',
  'гагары',
  'гагауз',
  'гагулы',
  'гадать',
  'гадиев',
  'гадина',
  'гадить',
  'гадлей',
  'гадоев',
  'гадром',
  'гадюка',
  'гадюки',
  'газали',
  'газаль',
  'газань',
  'газела',
  'газели',
  'газель',
  'газета',
  'газзан',
  'газить',
  'газука',
  'газыри',
  'газырь',
  'гайана',
  'гайдук',
  'гайзер',
  'гайзли',
  'гайлит',
  'гайлоз',
  'гайман',
  'гаймор',
  'гайоты',
  'гайсин',
  'гайтан',
  'гайхал',
  'гакать',
  'гаклун',
  'гакона',
  'гаксер',
  'галаго',
  'галапа',
  'галаты',
  'галаха',
  'галбан',
  'галган',
  'галеас',
  'галеви',
  'галега',
  'галеев',
  'галена',
  'галеон',
  'галеро',
  'галеры',
  'галета',
  'галеты',
  'галеть',
  'галина',
  'галион',
  'галиот',
  'галить',
  'галифе',
  'галлай',
  'галлан',
  'галлей',
  'галлер',
  'галлия',
  'галлон',
  'галман',
  'галмей',
  'галоид',
  'галоша',
  'галоши',
  'галузо',
  'галызь',
  'гальве',
  'гальго',
  'галька',
  'гальот',
  'гальюн',
  'галюка',
  'гамада',
  'гамаль',
  'гамаша',
  'гамаши',
  'гамаюн',
  'гамбия',
  'гамбоа',
  'гамель',
  'гамета',
  'гаметы',
  'гамить',
  'гаммас',
  'гаммет',
  'гамоны',
  'гамрус',
  'гамсун',
  'гамули',
  'ганать',
  'ганаши',
  'гангни',
  'гангут',
  'гандак',
  'гандер',
  'ганеша',
  'ганжур',
  'ганзен',
  'ганиев',
  'ганина',
  'ганита',
  'ганить',
  'ганнон',
  'гансен',
  'гантай',
  'ганьоа',
  'ганьсу',
  'гаолян',
  'гаосюн',
  'гапить',
  'гаптен',
  'гапуся',
  'гараев',
  'гаражи',
  'гарант',
  'гарбия',
  'гарбуз',
  'гарван',
  'гарвей',
  'гардал',
  'гардая',
  'гардез',
  'гарден',
  'гардин',
  'гардон',
  'гарево',
  'гареев',
  'гарига',
  'гариус',
  'гарлем',
  'гарман',
  'гармаш',
  'гармус',
  'гарнак',
  'гарнаш',
  'гарнец',
  'гарник',
  'гарнье',
  'гароди',
  'гарпаг',
  'гарпии',
  'гарпия',
  'гарпун',
  'гаррик',
  'гарсет',
  'гарсия',
  'гарсон',
  'гартли',
  'гаруда',
  'гаршин',
  'гарштя',
  'гарьян',
  'гасары',
  'гасать',
  'гасить',
  'гаскар',
  'гасман',
  'гасник',
  'гаспар',
  'гаспид',
  'гаспра',
  'гассер',
  'гастат',
  'гастев',
  'гастер',
  'гастон',
  'гастро',
  'гасфер',
  'гасший',
  'гасьон',
  'гатино',
  'гатить',
  'гатожь',
  'гатуев',
  'гафель',
  'гафний',
  'гафния',
  'гафури',
  'гахина',
  'гачуля',
  'гашник',
  'гашюры',
  'гаятри',
  'гбение',
  'гвадар',
  'гванда',
  'гварди',
  'гваюла',
  'гвиана',
  'гвинея',
  'гвозжу',
  'гдовцы',
  'гебезе',
  'гебоид',
  'гевара',
  'геварт',
  'гевонд',
  'гевьон',
  'гегард',
  'гедвиг',
  'гедель',
  'гедерн',
  'геджра',
  'гедике',
  'гедсер',
  'геенна',
  'геесты',
  'гезеке',
  'гезель',
  'гезенк',
  'гезира',
  'гезиты',
  'гезлам',
  'гейбец',
  'гейден',
  'гейнзе',
  'гейнсе',
  'геката',
  'гекеду',
  'геккер',
  'геккон',
  'геклен',
  'гексли',
  'гексод',
  'гектор',
  'гекуба',
  'гелати',
  'гелдер',
  'гелена',
  'гелина',
  'гелиэя',
  'геллер',
  'геллий',
  'гелоны',
  'гельма',
  'гельти',
  'гелями',
  'гемато',
  'гембеш',
  'гемера',
  'гемини',
  'генгам',
  'генган',
  'гендон',
  'генеты',
  'генкин',
  'генмаи',
  'геннин',
  'генный',
  'генрих',
  'генсек',
  'генсле',
  'гентин',
  'геншер',
  'геолог',
  'георге',
  'георги',
  'геофак',
  'геофил',
  'геофит',
  'геофон',
  'гепать',
  'гептан',
  'гептод',
  'геракл',
  'гераци',
  'герван',
  'гервег',
  'гергей',
  'герден',
  'гердер',
  'гереда',
  'гереро',
  'герике',
  'герион',
  'геркле',
  'геркон',
  'герлаш',
  'герлиц',
  'гермес',
  'гермия',
  'гернет',
  'гернси',
  'героин',
  'геронт',
  'героон',
  'геррес',
  'герсиф',
  'гертин',
  'герулы',
  'герцен',
  'гершун',
  'герюсы',
  'гесиод',
  'геснер',
  'геспер',
  'гессен',
  'гестия',
  'гетвуд',
  'гетера',
  'гетеро',
  'гетман',
  'гетнер',
  'геттер',
  'геттон',
  'гефест',
  'гефрес',
  'гецкин',
  'гжатск',
  'гжатцы',
  'гжелка',
  'гиалин',
  'гиалит',
  'гиатус',
  'гибать',
  'гиббет',
  'гибеон',
  'гибеть',
  'гигглз',
  'гигиея',
  'гигрин',
  'гиджак',
  'гиджра',
  'гидить',
  'гидрат',
  'гидрия',
  'гидроз',
  'гидрол',
  'гизель',
  'гиззат',
  'гиймен',
  'гикать',
  'гикори',
  'гилгил',
  'гилгит',
  'гилгуд',
  'гилман',
  'гилрей',
  'гильен',
  'гильза',
  'гильом',
  'гиляки',
  'гимбав',
  'гимель',
  'гимнет',
  'гимнот',
  'гингер',
  'гиндин',
  'гиндус',
  'гинуть',
  'гиоиды',
  'гиплер',
  'гипнос',
  'гипнум',
  'гиппий',
  'гиппус',
  'гирвас',
  'гирший',
  'гирька',
  'гиссар',
  'гистел',
  'гистио',
  'гитана',
  'гитаны',
  'гитега',
  'гитерс',
  'гитовы',
  'гиттис',
  'гиттия',
  'гифема',
  'гифнок',
  'гицель',
  'главка',
  'главый',
  'гладий',
  'гладко',
  'гладыш',
  'глажка',
  'глазах',
  'глазер',
  'глазет',
  'глазик',
  'глазки',
  'глазов',
  'глазок',
  'глазун',
  'гламур',
  'гланда',
  'гланды',
  'гларус',
  'гласис',
  'гласно',
  'глебик',
  'глебка',
  'глебов',
  'гледис',
  'глезер',
  'глезос',
  'гленко',
  'глечер',
  'глечик',
  'глизер',
  'гликон',
  'глимур',
  'глинда',
  'глинде',
  'глинка',
  'глиома',
  'глиста',
  'глисты',
  'глобин',
  'глобус',
  'гловно',
  'глогув',
  'глодов',
  'гломма',
  'глория',
  'глосса',
  'глотка',
  'глотку',
  'глоток',
  'глубже',
  'глузек',
  'глумец',
  'глумов',
  'глупая',
  'глупее',
  'глупец',
  'глупли',
  'глупый',
  'глупыш',
  'глухих',
  'глухов',
  'глухой',
  'глучин',
  'глушко',
  'глушня',
  'глыбка',
  'глысва',
  'глэшоу',
  'глюкоз',
  'глюоны',
  'глютин',
  'глянец',
  'гмелин',
  'гмыдня',
  'гнатюк',
  'гневно',
  'гнедая',
  'гнедич',
  'гнедка',
  'гнедко',
  'гнедой',
  'гнездо',
  'гнезно',
  'гнесин',
  'гнести',
  'гнесть',
  'гнеток',
  'гнетум',
  'гнилец',
  'гнилой',
  'гнозис',
  'гнозия',
  'гноить',
  'гноище',
  'гнойен',
  'гномон',
  'гнусно',
  'гнутый',
  'гнуштя',
  'гоацин',
  'гобель',
  'гобина',
  'гобино',
  'гобсек',
  'гобсон',
  'гобято',
  'говард',
  'говеть',
  'говнюк',
  'говори',
  'говоря',
  'говядо',
  'гоголи',
  'гоголь',
  'гогона',
  'гогочу',
  'годами',
  'годвин',
  'годжра',
  'година',
  'годить',
  'годнев',
  'годный',
  'годхра',
  'гожусь',
  'гознак',
  'голант',
  'голбец',
  'голдап',
  'голден',
  'голень',
  'голзти',
  'голиаф',
  'голина',
  'голить',
  'голица',
  'голице',
  'голицы',
  'голлум',
  'голова',
  'голову',
  'головы',
  'голоду',
  'голомо',
  'голомя',
  'голоса',
  'голота',
  'голошу',
  'голтон',
  'голуба',
  'голуби',
  'голубо',
  'голуха',
  'голуэй',
  'гольды',
  'гольня',
  'гольфы',
  'гольцы',
  'гольян',
  'голядь',
  'гомати',
  'гомбин',
  'гомель',
  'гомера',
  'гоммоз',
  'гомыра',
  'гонада',
  'гонады',
  'гонать',
  'гонвед',
  'гонгал',
  'гонгор',
  'гондек',
  'гондия',
  'гондэр',
  'гонзль',
  'гонион',
  'гонкий',
  'гонкур',
  'гонный',
  'гонощи',
  'гонтай',
  'гончак',
  'гончая',
  'гончие',
  'гончий',
  'гонщик',
  'гоньба',
  'гонять',
  'гопать',
  'гоплит',
  'гоплон',
  'гопота',
  'горазд',
  'горами',
  'горбач',
  'горбеа',
  'горбик',
  'горбов',
  'горбок',
  'горбун',
  'горбый',
  'горган',
  'горгий',
  'гордец',
  'гордин',
  'гордов',
  'гореве',
  'гореть',
  'горечи',
  'горечь',
  'горжет',
  'горини',
  'горище',
  'горкин',
  'горком',
  'горлан',
  'горлач',
  'горлик',
  'горлов',
  'горлык',
  'гормон',
  'горний',
  'горняк',
  'города',
  'городу',
  'горожу',
  'горока',
  'гороно',
  'горохи',
  'горсад',
  'горсей',
  'горсть',
  'горсуд',
  'гортер',
  'горула',
  'горхон',
  'горчак',
  'горший',
  'горщик',
  'горынь',
  'горько',
  'горюха',
  'горюша',
  'горяев',
  'горячо',
  'госала',
  'гослар',
  'госпел',
  'госпич',
  'госсек',
  'госсен',
  'гостей',
  'гостья',
  'гостях',
  'госхоз',
  'готама',
  'готами',
  'готово',
  'готоль',
  'готорн',
  'готхоб',
  'готшед',
  'гоулен',
  'гофман',
  'гошить',
  'гоэлро',
  'гояния',
  'грааль',
  'грабал',
  'граббе',
  'грабеж',
  'грабен',
  'грабин',
  'грабли',
  'грабов',
  'грабоу',
  'гравис',
  'градир',
  'градов',
  'градом',
  'граево',
  'гракхи',
  'грамма',
  'грамши',
  'гранби',
  'гранде',
  'гранды',
  'граник',
  'гранин',
  'гранка',
  'гранма',
  'грантс',
  'гранты',
  'граппа',
  'грасси',
  'гратис',
  'граубе',
  'графен',
  'графит',
  'графия',
  'графон',
  'графья',
  'грации',
  'грация',
  'грачац',
  'грачев',
  'граять',
  'гребец',
  'гребло',
  'гребок',
  'гревен',
  'грегор',
  'грегус',
  'грезет',
  'грейвс',
  'греков',
  'грелка',
  'гремин',
  'гремио',
  'гренаж',
  'гренаш',
  'гренки',
  'гренок',
  'гресли',
  'грести',
  'гресть',
  'гретри',
  'гретый',
  'грефье',
  'грехов',
  'грехом',
  'гречка',
  'гречко',
  'грешен',
  'грешик',
  'грешно',
  'грешок',
  'грибас',
  'грибов',
  'гривда',
  'гривка',
  'гривна',
  'гридин',
  'гридни',
  'гридня',
  'гризон',
  'гриква',
  'грильо',
  'гример',
  'гримма',
  'гринго',
  'гринда',
  'гринды',
  'гринев',
  'гринин',
  'гринок',
  'гриоты',
  'грипич',
  'грифея',
  'грифон',
  'грицай',
  'грицко',
  'грицюк',
  'грицюс',
  'гришин',
  'гришка',
  'гришко',
  'гробик',
  'гробок',
  'грогги',
  'гродно',
  'грозды',
  'гроздь',
  'грозец',
  'грозно',
  'гролта',
  'громко',
  'громов',
  'громче',
  'гронау',
  'гроней',
  'гронки',
  'гроссо',
  'гросул',
  'гротон',
  'грохот',
  'гроций',
  'грошик',
  'грубее',
  'грубер',
  'грубый',
  'грудка',
  'грудки',
  'грузка',
  'грузно',
  'грузон',
  'грумио',
  'грунин',
  'грухва',
  'грушас',
  'грушин',
  'грушка',
  'грыбув',
  'грызло',
  'грызня',
  'грызть',
  'грызун',
  'грымза',
  'грэмми',
  'грэхем',
  'грюйер',
  'грядка',
  'грязно',
  'грязца',
  'грясти',
  'гуанин',
  'гуанта',
  'гуанчи',
  'гуарда',
  'гуарне',
  'гуаява',
  'губаха',
  'губить',
  'губища',
  'губкин',
  'губной',
  'губоня',
  'гувейо',
  'гугала',
  'гугель',
  'гудейл',
  'гуделю',
  'гудено',
  'гудеть',
  'гуджар',
  'гуджир',
  'гудзий',
  'гудзон',
  'гудири',
  'гудить',
  'гудков',
  'гудмен',
  'гудрич',
  'гудрон',
  'гудрун',
  'гудула',
  'гудцов',
  'гудьир',
  'гуенна',
  'гужеед',
  'гуинес',
  'гуйсуй',
  'гуйсюй',
  'гукарь',
  'гукать',
  'гуково',
  'гулаев',
  'гуларт',
  'гулбис',
  'гулина',
  'гулить',
  'гулкий',
  'гулоян',
  'гульба',
  'гулька',
  'гульня',
  'гуляев',
  'гуляка',
  'гулямы',
  'гулять',
  'гумага',
  'гумели',
  'гуммит',
  'гуммоз',
  'гунить',
  'гункан',
  'гунтер',
  'гунтур',
  'гунхан',
  'гуньба',
  'гунька',
  'гураге',
  'гурами',
  'гурбан',
  'гурвич',
  'гурген',
  'гургои',
  'гуреев',
  'гуржий',
  'гурзуф',
  'гуриды',
  'гуриец',
  'гуркин',
  'гуркхи',
  'гурлен',
  'гурман',
  'гурмон',
  'гурния',
  'гуроны',
  'гуртик',
  'гуртом',
  'гурули',
  'гурунг',
  'гурьба',
  'гурьев',
  'гусаны',
  'гусары',
  'гусева',
  'гуситы',
  'гусйты',
  'гуслин',
  'гусляр',
  'гусник',
  'густав',
  'густой',
  'гусыня',
  'гутара',
  'гутира',
  'гутман',
  'гутный',
  'гуттен',
  'гутулы',
  'гутыря',
  'гуцков',
  'гуцулы',
  'гучков',
  'гущина',
  'гуэрра',
  'гхотки',
  'гыкать',
  'гэбист',
  'гэбуха',
  'гэньхэ',
  'гюзель',
  'гюстен',
  'гюцков',
  'гянджа',
  'гяуров',
].concat(WORDS)
