import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Вдруг вы не знаете, как играть в Wordle"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        6 попыток. После каждой попытки цвет ячеек поменяется, показывая,
        насколько близок был ваш ответ.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Г" status="correct" />
        <Cell value="А" />
        <Cell value="В" />
        <Cell value="А" />
        <Cell value="Й" />
        <Cell value="И" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буква "Г" в слове в нужном месте.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Г" status="correct" />
        <Cell value="Р" />
        <Cell value="А" />
        <Cell value="А" />
        <Cell value="Л" status="present" />
        <Cell value="Ь" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буква "Л" есть в слове, но в неверном месте.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Г" status="correct" />
        <Cell value="А" />
        <Cell value="Д" />
        <Cell value="Ю" />
        <Cell value="К" status="absent" />
        <Cell value="А" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквы "К" нет в этом слове.
      </p>
    </BaseModal>
  )
}
