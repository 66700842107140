export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Пойдет', 'Приемлемо', 'Неплохо', 'Г']
export const GAME_COPIED_MESSAGE = 'Скопировано'
export const ABOUT_GAME_MESSAGE = 'О игре'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Маловато будет'
export const WORD_NOT_FOUND_MESSAGE =
  'Не знаем такого слова, попробуйте что-нибудь на букву "Г"'
export const HARD_MODE_ALERT_MESSAGE =
  'Поздно пить боржоми, хардмод включается только в начале'
export const CORRECT_WORD_MESSAGE = (solution: string) => `Ответ: ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `В позиции ${position} должна быть буква ${guess}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Слово должно содержать ${letter}`
export const ENTER_TEXT = 'Энтер'
export const DELETE_TEXT = 'Стереть'
export const STATISTICS_TITLE = 'Статы'
export const GUESS_DISTRIBUTION_TEXT = 'Красивый график'
export const NEW_WORD_TEXT = 'Следующее'
export const SHARE_TEXT = 'Заебать ленту'
export const TOTAL_TRIES_TEXT = 'Попыток'
export const SUCCESS_RATE_TEXT = 'Успешно'
export const CURRENT_STREAK_TEXT = 'Стрик'
export const BEST_STREAK_TEXT = 'Лучший стрик'
