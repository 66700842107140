export const WORDS = [
  'гибель',
  'гравий',
  'герпес',
  'грибок',
  'галлий',
  'гейзер',
  'глицин',
  'глагол',
  'гайдар',
  'гондон',
  'гексан',
  'гигант',
  'грузия',
  'гвоздь',
  'галкин',
  'гешефт',
  'гибсон',
  'гегель',
  'геринг',
  'гитлер',
  'гиблый',
  'гиббон',
  'градус',
  'галера',
  'гибщик',
  'гектар',
  'гопник',
  'греция',
  'гравер',
  'гитара',
  'гипноз',
  'готика',
  'глазго',
  'гепард',
  'гончар',
  'группа',
  'гитлаб',
  'гинкго',
  'герань',
  'гаечка',
  'герцог',
  'гоблин',
  'геймер',
  'гранит',
  'гарный',
  'гриндр',
  'груздь',
  'горный',
  'гудвин',
  'гидрид',
  'гризли',
  'графин',
  'гилмор',
  'гамлет',
  'гордый',
  'герман',
  'гадкий',
  'голубь',
  'график',
  'готлиб',
  'гордон',
  'грусть',
  'гибрид',
  'гарнир',
  'гамбит',
  'гелиос',
  'гребля',
  'гномик',
  'наебал',
  'горшок',
  'гитхаб',
  'глютен',
  'гейгер',
  'гранат',
  'галант',
  'гайдай',
  'гюнтер',
  'гибкий',
  'грузин',
  'гейдар',
]
